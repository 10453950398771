@import "../../assets/styles/variables.scss";
.survey {
  text-align: center;
  padding-bottom: 100px;

  .question-box {
    margin-bottom: 40px;

    h2 {
      font-size: 40px;
      margin: 0;
      font-weight: 400;
    }
  }

  .option-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      width: 425px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .back-btn {
    padding: 5px 10px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 14px;
    min-width: 100px;
    position: absolute;
    left: 40px;
    bottom: 20px;
  }

  .content-box {
    .text {
      font-size: 22px;
      margin-bottom: 35px;
    }

    span {
      color: $theme-primary-color;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;

    label {
      margin-bottom: 3px;
    }
    .form-control {
      width: 100%;
      padding: 12px 20px;
      font-size: 18px;
      border: 1px solid $theme-white-color;
      background: none;
      color: $theme-white-color;
      border-radius: 4px;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $theme-primary-color;
      }
    }

    // input[type="date"]::-webkit-calendar-picker-indicator {
    //   background: transparent;
    //   bottom: 0;
    //   color: transparent;
    //   cursor: pointer;
    //   height: auto;
    //   left: 0;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   width: auto;
    // }
  }

  .thankyou {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .policy-content {
    a {
      color: $theme-primary-color;
    }
  }
}

/*--### RESPONSIVE ###--*/
@media (max-width: 767px) {
  .survey .question-box h2 {
    font-size: 26px;
    padding: 0 30px;
  }
  .survey .option-box .button {
    font-size: 16px;
    padding: 14px 10px;
    width: 90%;
    margin-bottom: 20px;
  }
  .content-box {
    padding: 0 30px;
  }
  .survey .content-box .text {
    font-size: 18px;

    h2 {
      font-size: 26px;
    }
  }

  .survey .input-wrapper .form-control {
    padding: 10px 15px;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 49px;
  }
  .survey .input-wrapper {
    width: 90%;
    margin-bottom: 15px;
  }
  .survey .form-wrapper .form-group {
    margin: 0 auto;
    width: 90%;
  }
}

@media(min-width:400px) {
  .survey {
    .form-wrapper {
    width: 400px;
    margin: 0 auto;

    &.postal {

      .form-group {
        .input-wrapper {
          &:first-child {
            width: 180px;
          }
      }
      }
    }
    
    .form-group {
      display: flex;

      .input-wrapper {
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
  }
}
