@import "../../assets/styles/variables.scss";

/*--### Banner Section ###--*/
.banner-section {
  .row {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    .button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

/*--### WHAT WE OFFER ###--*/
.what-we-offer {
  padding: 100px 0px;
  color: $theme-white-color;

  .video-wrapper {
    display: flex;
    justify-content: center;

    iframe {
      width: 700px;
      height: 400px;
      margin: 0 auto;
    }
  }

  .content-box {
    padding: 80px;
    width: fit-content;
    margin: 0 auto;

    h3 {
      margin: 0;
      font-size: 40px;

      span {
        color: $theme-primary-color;
      }
    }

    hr {
      border-color: $theme-primary-color;
      border-width: 2px;
      border-bottom: 0px;
    }

    ul {
      margin-top: 20px;
      padding-left: 25px;

      li {
        font-size: 20px;
        margin-bottom: 7px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .row {
    padding: 0 30px;
    align-items: center;
  }
}

/*--### RESPONSIVE ###--*/
@media (max-width: 767px) {
  .what-we-offer .video-wrapper iframe {
    width: 90%;
    height: 300px;
  }
  .what-we-offer .content-box h3 {
    font-size: 30px;
  }
  .what-we-offer .content-box ul li {
    font-size: 18px;
    margin-bottom: 7px;
  }

  .what-we-offer .content-box {
    padding: 50px 30px;
  }

  .what-we-offer .row {
    flex-direction: column;
    padding: 0px;

    .col-6 {
      width: 100%;
    }
  }
}
