@import "./assets/styles/variables.scss";
@import "../node_modules/aos/dist/aos.css";
@import "../node_modules/accept-cookies/accept-cookies.css";

.App {
  position: relative;
  background-size: cover;
  min-height: 100vh;
  color: $theme-white-color;

  .background-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $theme-black-color;
    opacity: 0.7;
    z-index: -1;
  }
  .background-image {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
}

.accept-cookies {
  .accept-cookies__button {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background-color: $theme-primary-color;
      color: $theme-white-color;
    }
  }

  .accept-cookies__text {
    a {
      color: $theme-primary-color;
    }
  }
}

.bottom-page-link {
  color: $theme-primary-color;
  position: absolute;
  right: 20px;
  bottom: 15px;
}
