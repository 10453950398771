.content-page {
  color: #000;
  background-color: #fff;
  padding: 90px 150px;

  ul, ol {
    padding-left: 0;
    list-style-type: none;
  }

  @media(max-width: 767px) {
    padding: 30px 50px;
  }
}
