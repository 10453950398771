@import "./variables.scss";

* {
  box-sizing: border-box;
}

/*--### COLUMNS ###--*/
.col-1 {
  width: calc(100% / 12);
}
.col-2 {
  width: calc(100% / 12 * 2);
}
.col-3 {
  width: calc(100% / 12 * 3);
}
.col-4 {
  width: calc(100% / 12 * 4);
}
.col-5 {
  width: calc(100% / 12 * 5);
}
.col-6 {
  width: calc(100% / 12 * 6);
}
.col-7 {
  width: calc(100% / 12 * 7);
}
.col-8 {
  width: calc(100% / 12 * 8);
}
.col-9 {
  width: calc(100% / 12 * 9);
}
.col-10 {
  width: calc(100% / 12 * 10);
}
.col-11 {
  width: calc(100% / 12 * 11);
}
.col-12 {
  width: calc(100% / 12 * 12);
}

.sdp--date-btn__selected {
  background-color: $theme-primary-color !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
input,
textarea,
form,
label,
button,
div,
section {
  font-family: "Poppins", sans-serif;
}

/*--### LIST STYLES ###--*/
ul {
  padding: 0;
  margin: 0;
}

/*--### CONTAINERS ###--*/
.container {
  max-width: 1320px;
  margin: 0 auto;
}

.container-fluid {
  max-width: 100%;
  padding: 0 30px;
}

/*--### ROW ###--*/
.row {
  display: flex;
  flex-wrap: wrap;
}

/*--### IMAGE RESPONSIVE ###--*/
.img-responsive {
  width: 100%;
}

/*--### SECTION HEADING ###--*/
.section-heading {
  margin-bottom: 50px;

  h1 {
    color: $theme-white-color;
    font-size: 45px;
    line-height: 60px;
    margin: 0;
  }
}

/*--### BUTTON STYLES ###--*/
.button {
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 500;
  background-color: $theme-primary-color;
  color: $theme-white-color;
  border: 1px solid $theme-primary-color;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.4s all;

  &:hover {
    background-color: $theme-black-color;
  }

  &.outline {
    border-color: $theme-white-color;
    background-color: transparent;
    font-size: 22px;
    padding: 27px 40px;

    &:hover {
      border-color: $theme-primary-color;
      background-color: $theme-primary-color;
    }
  }
}

/*--### RESPONSIVE ###--*/
@media (max-width: 767px) {
  .section-heading h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .button {
    padding: 15px 30px;
    font-size: 16px;
  }
  .button.outline {
    font-size: 18px;
    padding: 20px;
  }
  .survey .input-wrapper {
    width: 90%;

    .form-control {
      width: 90%;
    }
  }
}
