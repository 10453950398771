.header {
  padding: 50px 0;
  .row {
    justify-content: center;
  }
  .brand-logo {
    width: 180px;
  }

  .external-link {
    width: 130px;
    display: flex;
    position: absolute;
    right: 40px;
    top: 40px;
  }
}

@media (max-width: 767px) {
  .header .external-link {
    position: initial;
    width: 100px;
  }
  .header .brand-logo {
    width: 110px;
  }
  .header {
    padding: 20px 30px;
    padding-bottom: 40px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
