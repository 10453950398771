@import "../../assets/styles/variables.scss";

.progressBar-wrapper {
  width: 100%;

  .progress-bar {
    background-color: $theme-white-color;
    width: 100%;
    border-radius: 100px;

    .progress-indicator {
      background-color: $theme-primary-color;
      color: $theme-white-color;
      text-align: right;
      font-size: 20px;
      border-radius: 100px;
      height: 7px;
      transition: 0.3s all;
    }
  }
}

.survey-header {
  padding: 25px 0px;
  .row {
    justify-content: space-between;
    align-items: center;

    .brand-logo {
      width: 120px;
    }
  }

  .progress-container {
    width: 130px;
  }
}

/*--### RESPONSIVE ###--*/
@media (max-width: 767px) {
  .survey-header {
    padding: 15px 30px;
  }
  .survey-header .row {
    // flex-direction: column;
  }

  .survey-header .row .brand-logo {
    width: 80px;
  }

  .survey-header .progress-container {
    width: 70px;
    margin-top: 0px;
  }

  .progressBar-wrapper .progress-bar .progress-indicator {
    height: 5px;
  }
}
